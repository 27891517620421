const ConfigurationList = async () =>
    import('../../pages/email-to-order/List').then((module) => ({
        Component: module.EmailToOrderList,
    }));
const ConfigurationView = async () =>
    import('../../pages/email-to-order/View').then((module) => ({
        Component: module.EmailToOrderView,
    }));

const getEmailToOrderRouter = (_user, _features) => [
    {
        path: '/pdf-configs',
        lazy: ConfigurationList,
    },
    {
        path: '/pdf-configs/:id',
        lazy: ConfigurationView,
    },
];

export { getEmailToOrderRouter };
