export enum BACKEND_URL_LIST {
    PRODUCTION = 'https://production.backend.spacefill.fr',
    STAGING = 'https://staging.backend.spacefill.fr',
    SANDBOX = 'https://sandbox.backend.spacefill.fr',
}

export enum GRAPHQL_API_URL_LIST {
    PRODUCTION = 'https://production.graphql.spacefill.fr/graphql',
    STAGING = 'https://staging.graphql.spacefill.fr/graphql',
    SANDBOX = 'https://sandbox.graphql.spacefill.fr/graphql',
}

export enum GRAPHQL_ATTACHMENTS_URL_LIST {
    PRODUCTION = 'https://production.graphql.spacefill.fr/attachments',
    STAGING = 'https://staging.graphql.spacefill.fr/attachments',
    SANDBOX = 'https://sandbox.graphql.spacefill.fr/attachments',
}

export const BACKEND_LIST_LABEL = {
    LOCAL: 'Local',
    PRODUCTION: 'Production',
    STAGING: 'Staging',
    SANDBOX: 'Sandbox',
};

export const BACKEND_LIST = {
    LOCAL: 'LOCAL',
    PRODUCTION: 'PRODUCTION',
    STAGING: 'STAGING',
    SANDBOX: 'SANDBOX',
};
