import { useState } from 'react';
import constate from 'constate';

function useSideBar() {
    const [isSizeBarCollapsed, setIsSizeBarCollapsed] = useState(
        window.localStorage.getItem('isSizeBarCollapsed') === 'true'
    );

    return { isSizeBarCollapsed, setIsSizeBarCollapsed };
}

const [SideBarContextProvider, useSideBarContext] = constate(useSideBar);

export { SideBarContextProvider, useSideBarContext };
