import React from 'react';
import ReactDOM from 'react-dom/client';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';
import { pingGraphQLServer } from '@spacefill/shared/src/utils/Apollo';
import '@spacefill/shared/src/utils/YupMethods';
import { datadogRum } from '@datadog/browser-rum';

import '@spacefill/uikit/src/index.css';
import { App } from './App';

const datadogApplicationId =
    window?._spacefill_env_?.datadogApplicationId || import.meta.env?.VITE_ADMIN_CONSOLE_DATADOG_APPLICATION_ID;
const datadogClientToken =
    window?._spacefill_env_?.datadogClientToken || import.meta.env?.VITE_ADMIN_CONSOLE_DATADOG_CLIENT_TOKEN;

const enableMockTesting = new URLSearchParams(window.location.search).get('enableMockTesting');
if (enableMockTesting) {
    window.localStorage.setItem('enableMockTesting', '1');
}

if (datadogApplicationId) {
    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'admin-console',
        env: import.meta.env?.VITE_ADMIN_CONSOLE_SENTRY_ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        allowedTracingUrls: [(url) => url.includes('spacefill.fr')],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

if (window.navigator.userAgent === 'puppeteer') {
    window.toast = toast;
}

pingGraphQLServer((error) => {
    toast.error(t('Internal error!'));
    console.error(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
