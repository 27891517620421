import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { clsx } from 'clsx';
import { faChevronDown, faChevronUp, faGripDotsVertical } from '@fortawesome/pro-light-svg-icons';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    BACKEND_LIST,
    BACKEND_LIST_LABEL,
    BACKEND_URL_LIST,
    GRAPHQL_API_URL_LIST,
    GRAPHQL_ATTACHMENTS_URL_LIST,
} from '@spacefill/shared/src/constants/Backend';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';

type ToggleFeatureToolbarProps = {
    features: Record<string, boolean>;
    toggleFeature: (featureName: string) => unknown;
    toolbarIsOpen: boolean;
    setToolbarIsOpen: (toolbarIsOpen: boolean) => void;
    dropzoneEnabled: boolean;
    setDropzoneEnabled: (dropzoneEnabled: boolean) => void;
};

export function ToggleFeatureToolbar({
    features,
    toggleFeature,
    toolbarIsOpen,
    setToolbarIsOpen,
    dropzoneEnabled,
    setDropzoneEnabled,
}: ToggleFeatureToolbarProps) {
    const toggleToolbarVisibility = () => setToolbarIsOpen(!toolbarIsOpen);

    const backends = Object.values(BACKEND_LIST);
    const [selectedBackend, setSelectedBackend] = useState(() => {
        const backendUrl = getEnvironmentVariable('backendUrl');
        const backendKey = Object.keys(BACKEND_URL_LIST).find(
            (key) => BACKEND_URL_LIST[key as keyof typeof BACKEND_URL_LIST] === backendUrl
        );
        return backendKey || 'LOCAL';
    });

    return (
        <Fragment>
            <div
                id='toggle-features-container'
                className='w-fit cursor-grab border-4 border-solid border-red-500 bg-yellow-300 p-2'
                draggable
                onDragStart={(e) => {
                    const element = e.target as HTMLElement;
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.dropEffect = 'move';
                    element.dataset.mouseOffsetX = String(e.clientX - element.offsetLeft);
                    element.dataset.mouseOffsetY = String(e.clientY - element.offsetTop);
                    setDropzoneEnabled(true);
                }}
                onDragEnd={(e) => {
                    const element = e.target as HTMLElement;
                    if (e.clientY > 0 && e.clientY < window.innerHeight) {
                        element.style.bottom = `${
                            window.innerHeight - e.clientY - element.offsetHeight + Number(element.dataset.mouseOffsetY)
                        }px`;
                    }
                    if (e.clientX > 0 && e.clientX < window.innerWidth) {
                        element.style.left = `${e.clientX - Number(element.dataset.mouseOffsetX)}px`;
                    }
                    setDropzoneEnabled(false);
                }}
            >
                <div className='flex select-none items-center justify-between p-1 font-medium'>
                    <div>
                        <FontAwesomeIcon icon={faGripDotsVertical} /> Toggle features
                    </div>
                    <button
                        className='ml-4 text-left'
                        onClick={toggleToolbarVisibility}
                    >
                        {toolbarIsOpen ? (
                            <>
                                Close <FontAwesomeIcon icon={faChevronDown} />
                            </>
                        ) : (
                            <>
                                Open <FontAwesomeIcon icon={faChevronUp} />
                            </>
                        )}
                    </button>
                </div>
                {toolbarIsOpen ? (
                    <div className='flex flex-col gap-2'>
                        <SelectBackend
                            backends={backends}
                            selectedBackend={selectedBackend}
                            setSelectedBackend={setSelectedBackend}
                        />
                        <ListOfFeatureTogglingCheckboxes
                            features={features}
                            toggleFeature={toggleFeature}
                        />
                    </div>
                ) : null}
            </div>
            <div
                className={clsx([
                    dropzoneEnabled ? 'w-full' : 'w-0',
                    dropzoneEnabled ? 'h-full' : 'h-0',
                    dropzoneEnabled ? 'fixed' : 'absolute',
                    dropzoneEnabled ? 'z-[99999]' : 'z-[-99999]',
                ])}
                onDragOver={(ev) => ev.preventDefault()}
            />
        </Fragment>
    );
}

const ListOfFeatureTogglingCheckboxes = ({
    features,
    toggleFeature,
}: {
    features: Record<string, boolean>;
    toggleFeature: (featureName: string) => unknown;
}) => (
    <ul className='mx-0 my-1 pl-1'>
        {Object.keys(features).map((featureName) => (
            <li
                key={featureName}
                className='mb-1'
            >
                <label className='cursor-pointer'>
                    <input
                        type='checkbox'
                        className='mr-1'
                        name={featureName}
                        checked={features[featureName]}
                        onChange={() => toggleFeature(featureName)}
                    />
                    {featureName}
                </label>
            </li>
        ))}
    </ul>
);

const SelectBackend = ({
    backends,
    selectedBackend,
    setSelectedBackend,
}: {
    backends: string[];
    selectedBackend: string;
    setSelectedBackend: Dispatch<SetStateAction<string>>;
}) => {
    const { logout } = useUserContext();
    const handleChooseBackend = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedBackend(e.target.value);
        if (e.target.value === BACKEND_LIST.LOCAL) {
            window.sessionStorage.removeItem('backendUrl');
            window.sessionStorage.removeItem('graphqlApiUrl');
            window.sessionStorage.removeItem('attachmentsUrl');
        } else {
            window.sessionStorage.setItem(
                'backendUrl',
                BACKEND_URL_LIST[e.target.value as keyof typeof BACKEND_URL_LIST]
            );
            window.sessionStorage.setItem(
                'graphqlApiUrl',
                GRAPHQL_API_URL_LIST[e.target.value as keyof typeof GRAPHQL_API_URL_LIST]
            );
            window.sessionStorage.setItem(
                'attachmentsUrl',
                GRAPHQL_ATTACHMENTS_URL_LIST[e.target.value as keyof typeof GRAPHQL_ATTACHMENTS_URL_LIST]
            );
        }
        logout();
        window.location.reload();
    };
    return (
        <div className='mt-2 flex flex-col gap-0.5 px-1'>
            <label className='mr-2 text-xs text-gray-700'>Backend</label>
            <select
                className='rounded'
                value={selectedBackend}
                onChange={handleChooseBackend}
            >
                {backends.map((database) => (
                    <option
                        key={database}
                        value={database}
                    >
                        {BACKEND_LIST_LABEL[database as keyof typeof BACKEND_LIST_LABEL]}
                    </option>
                ))}
            </select>
        </div>
    );
};
