import Cookies from 'universal-cookie';

import { getEnvironmentVariable } from './GetEnvVar';

const cookies = new Cookies();
const envPrefix = getEnvironmentVariable('envPrefix');
const backendUrl = getEnvironmentVariable('backendUrl');
if (envPrefix === 'EXTERNAL-CONSOLE' && !backendUrl) {
    console.error('Backend URL not found');
}

export const isDemo = (backendUrl || '').includes('demo');
export const isSandBox = (backendUrl || '').includes('sandbox');

type ErrorWithDetailsInit = {
    message: string;
    details: string;
    lastResponse?: Response;
};

class ErrorWithDetails extends Error {
    details: string;
    lastResponse?: Response;

    constructor({ message, details, lastResponse }: ErrorWithDetailsInit) {
        super(message);
        this.details = details;
        /**
         * super(message) transform an array of string into a string,
         * lastResponse allows to keep and re-use the last response without transformation.
         */
        this.lastResponse = lastResponse;
    }
}

export async function call<R extends unknown>(
    route: string,
    params: Record<string, unknown> = {},
    method: Request['method'] = 'GET',
    headers: HeadersInit = {},
    signal = undefined
) {
    const response = route.startsWith('http')
        ? await fetchExternal(route, params, method, headers, signal)
        : await fetchBackend(route, params, method, headers, signal);

    if (!response) {
        return;
    }

    if (!response.ok) {
        let errorDetails: ErrorWithDetailsInit;
        try {
            const responseAsJson = await response.json();
            errorDetails = {
                message: responseAsJson.error || responseAsJson.message || 'Unknown error from server.',
                details: responseAsJson.details || responseAsJson,
            };
        } catch (e) {
            const responseText = await response.text();
            errorDetails = {
                message: `Unexpected error with status ${response.status}: ${e}`,
                details: responseText,
            };
        }
        console.log(`errorDetails: `, errorDetails);
        throw new ErrorWithDetails(errorDetails);
    }
    if (response.status === 204) {
        return Promise.resolve({} as R);
    }

    try {
        return (await response.json()) as R;
    } catch (error) {
        console.error('Parsing JSON error on route: ', route, error);
        return {} as R;
    }
}

export async function download(
    route: string,
    params: Record<string, unknown>,
    method: RequestInit['method'] = 'GET',
    headers: HeadersInit = {}
) {
    const response = await fetchBackend(route, params, method, headers);
    if (!response) {
        return;
    }

    if (!response.ok) {
        const responseAsJson = await response.json();
        throw new Error(responseAsJson.message);
    }

    return response.blob();
}

async function fetchBackend(
    route: string,
    params: Record<string, unknown> = {},
    method: Request['method'],
    additionalHeaders: HeadersInit = {},
    signal = undefined
) {
    if (!backendUrl) {
        return null;
    }

    const isUsingBody = ['POST', 'PUT', 'PATCH'].includes(method);
    let body: BodyInit = ''; // Built with FormData or JSON if no files will be provided

    const jwtToken = cookies.get(getJwtTokenName());
    const isImpersonated = !!cookies.get('jwtTokenExternalConsoleImpersonator');
    const headers: HeadersInit = new Headers({
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
        ...(isImpersonated && { 'Spacefill-Ctx-Impersonated': '1' }),
        ...additionalHeaders,
    });

    const url = new URL(backendUrl);
    url.pathname = route;
    let requestContainsFiles = false;
    if (isUsingBody) {
        let formData = new FormData(); // FormData or JSON if no files will be provided

        // Extract files from params to add them in FormData
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value) && value.length > 0 && value.some((item) => item instanceof File)) {
                for (const file of value) {
                    formData.append(key, file);
                }
                delete params[key];
                requestContainsFiles = true;
            } else if (value instanceof File) {
                formData.append(key, value);
                delete params[key];
                requestContainsFiles = true;
                break;
            }
        }

        if (requestContainsFiles) {
            formData.append('body', JSON.stringify(params));
            // Do not send multipart/form-data to let the browser set the correct content-type
            headers.delete('Content-Type');
            body = formData;
        } else {
            body = JSON.stringify(params);
        }
    } else {
        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, String(value));
        }
    }

    return fetch(url, {
        headers,
        method,
        mode: 'cors',
        ...(isUsingBody ? { body } : {}),
        signal,
    });
}

async function fetchExternal(
    route: string,
    params: Record<string, unknown> = {},
    method: Request['method'],
    additionalHeaders: HeadersInit = {},
    signal = undefined
) {
    const url = new URL(route);
    const isUsingBody = ['POST', 'PUT', 'PATCH'].includes(method);
    let body: BodyInit = ''; // Built with FormData or JSON if no files will be provided

    const headers: HeadersInit = new Headers({
        'Content-Type': 'application/json',
        ...additionalHeaders,
    });

    let requestContainsFiles = false;
    if (isUsingBody) {
        let formData = new FormData(); // FormData or JSON if no files will be provided

        // Extract files from params to add them in FormData
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value) && value.length > 0 && value.some((item) => item instanceof File)) {
                for (const file of value) {
                    formData.append(key, file);
                }
                delete params[key];
                requestContainsFiles = true;
            } else if (value instanceof File) {
                formData.append(key, value);
                delete params[key];
                requestContainsFiles = true;
                break;
            }
        }

        if (requestContainsFiles) {
            formData.append('body', JSON.stringify(params));
            // Do not send multipart/form-data to let the browser set the correct content-type
            headers.delete('Content-Type');
            body = formData;
        } else {
            body = JSON.stringify(params);
        }
    } else {
        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, String(value));
        }
    }

    return fetch(url, {
        headers,
        method,
        mode: 'cors',
        ...(isUsingBody ? { body } : {}),
        signal,
    });
}

function getJwtTokenName() {
    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            return 'jwtTokenAdminConsole';
        case 'EXTERNAL-CONSOLE':
            return 'jwtTokenExternalConsole';
        default:
            throw new Error('Unknown environment prefix');
    }
}

export function granularityFieldsToQueryParam(object: Record<string, { value: string }>, prefix = '') {
    const query: Record<string, string> = {};
    for (const [key, option] of Object.entries(object)) {
        if (option?.value) {
            query[`${prefix}[${key}]`] = option.value;
        }
    }
    return query;
}
